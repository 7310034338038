"use client"

import { useCallback, useEffect, useState } from "react"
import { useIsMobile } from "../../hooks"
import { useUser } from "@clerk/nextjs"
import { IntercomMessengerAttributes } from "./Intercom"

// Make sure to add "@types/intercom-web" in app tsconfig types

type IntercomProps = {
  intercomId: string
  settings?: IntercomMessengerAttributes
}

export const IntercomWidget = (props: IntercomProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isBooted, setIsBooted] = useState(false)
  const isMobile = useIsMobile()
  const { isLoaded: sessionLoaded, isSignedIn, user } = useUser()

  const bootIntercom = useCallback(() => {
    if (
      !user ||
      !user.fullName ||
      !user.primaryEmailAddress ||
      !user.externalId
    )
      return
    window.Intercom("boot", {
      app_id: props.intercomId,
      name: user.fullName,
      email: user.primaryEmailAddress.emailAddress,
      user_id: user.externalId,
    })
    setIsBooted(true)
  }, [props.intercomId, user])

  useEffect(() => {
    if (isMobile || isLoaded || !sessionLoaded || !isSignedIn) return
    window.intercomSettings = props.settings
    loadIntercom(props.intercomId, () => setIsLoaded(true))
  }, [
    props.intercomId,
    props.settings,
    isMobile,
    isLoaded,
    sessionLoaded,
    isSignedIn,
  ])

  useEffect(() => {
    if (!isLoaded || !window.Intercom) return
    bootIntercom()
  }, [bootIntercom, isLoaded])

  useEffect(() => {
    if (!isLoaded || !window.Intercom) return
    window.Intercom("update", { hide_default_launcher: isMobile })
  }, [isLoaded, isMobile])

  useEffect(() => {
    if (
      !isBooted ||
      !window.Intercom ||
      !user ||
      !user.fullName ||
      !user.primaryEmailAddress
    )
      return
    window.Intercom("update", {
      name: user.fullName,
      email: user.primaryEmailAddress.emailAddress,
    })
  }, [isBooted, user?.primaryEmailAddress, user?.fullName])

  useEffect(() => {
    if (isBooted && sessionLoaded && !isSignedIn) {
      window.Intercom("shutdown")
      setIsBooted(false)
    }
  }, [isSignedIn, isBooted, sessionLoaded])

  return null
}

function loadIntercom(appId: string, onSuccess: () => void) {
  const w = window as any
  const ic = w.Intercom
  if (typeof ic === "function") {
    ic("reattach_activator")
    ic("update", w.intercomSettings)
  } else {
    const d = document
    const i: any = function (...params: unknown[]) {
      i.c(params)
    }
    i.q = []
    i.c = function (args: unknown) {
      i.q.push(args)
    }
    w.Intercom = i
    const l = function () {
      const s = d.createElement("script")
      s.type = "text/javascript"
      s.async = true
      s.src = `https://widget.intercom.io/widget/${appId}`
      const x = d.getElementsByTagName("script")[0]
      x.parentNode?.insertBefore(s, x)
      onSuccess()
    }
    if (document.readyState === "complete") {
      l()
    } else if (w.attachEvent) {
      w.attachEvent("onload", l)
    } else {
      w.addEventListener("load", l, false)
    }
  }
}
