import(/* webpackMode: "eager", webpackExports: ["ServiceWorker"] */ "/vercel/path0/apps/store-frontend/_components/ServiceWorker.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/store-frontend/app/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/backend/api-client/src/analytics.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/backend/api-client/src/components/PageViewObserver.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/backend/api-client/src/components/UserIdentityObserver.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/backend/api-client/src/hooks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntercomWidget"] */ "/vercel/path0/libs/ui/common/src/components/Intercom/IntercomWidget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PwaInstallDialog"] */ "/vercel/path0/libs/ui/common/src/components/PwaInstallDialog/PwaInstallDialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/libs/ui/common/src/components/Toast/Toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
