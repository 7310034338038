"use client"

import { useEffect } from "react"

export function ServiceWorker() {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/service-worker.js", { scope: "/" })
        .then((registration) => {
          console.log(
            "Service worker registered successfully. Scope:",
            registration.scope,
          )
        })
        .catch((error) => {
          console.error("Service worker registration failed:", error)
        })

      let refreshing = false
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (!refreshing) {
          window.location.reload()
          refreshing = true
        }
      })
    }
  }, [])

  return null
}
