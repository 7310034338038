"use client"

import { HTMLAttributes, useEffect } from "react"

interface PwaInstallDialogProps extends HTMLAttributes<HTMLDivElement> {
  app: string
  theme?: "dark" | "light"
  open?: true
}

export const PwaInstallDialog = ({ app, ...props }: PwaInstallDialogProps) => {
  useEffect(() => {
    import("@storedotapp/pwa-install-dialog")
  }, [])

  return <pwa-install-dialog app={app} {...props} />
}
