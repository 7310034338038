"use client"

import { usePathname, useSearchParams } from "next/navigation"
import { useEffect } from "react"
import { useAnalytics, useAnonAnalytics } from ".."

export function PageViewObserver() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { trackerReady, trackPage, trackFbClickId } = useAnalytics()

  useEffect(() => {
    if (trackerReady) trackPage(pathname, searchParams)
  }, [pathname, searchParams, trackerReady, trackPage])

  useEffect(() => {
    const fbClickId = searchParams.get("fbclid")
    if (fbClickId) trackFbClickId(fbClickId)
  }, [searchParams, trackFbClickId])

  return null
}

export function PageViewAnonymousObserver() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { trackerReady, trackPage, trackFbClickId } = useAnonAnalytics()

  useEffect(() => {
    if (trackerReady) trackPage(pathname, searchParams)
  }, [pathname, searchParams, trackerReady, trackPage])

  useEffect(() => {
    const fbClickId = searchParams.get("fbclid")
    if (fbClickId) trackFbClickId(fbClickId)
  }, [searchParams, trackFbClickId])

  return null
}
