"use client"

import { useEffect } from "react"
import { useAnalytics } from ".."
import { useSession } from "@clerk/nextjs"

export function UserIdentityObserver() {
  const { trackerReady, trackIdentify } = useAnalytics()
  const { session } = useSession()

  useEffect(() => {
    if (trackerReady && session?.user.externalId) trackIdentify()
  }, [trackerReady, session?.user.externalId])

  return null
}
